import { Typography, Grid, Select, MenuItem, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import ContentContainer from '../ContentContainer'
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router';
import CustomTextField from '../TextFields/CustomTextField'
import Checkbox from "@mui/material/Checkbox";
import ValidationDialog from '../ValidationDialog'
import { SpinnerModal } from '../Modals/SpinnerModal';

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },

    checkboxRoot: {
        color: "#134169!important"
    }

});

export function Reports() {
    const resource = global.resources[useTheme().locale];
    const classes = useStyles();
    let { type } = useParams();
    let typeName = ""
    switch (type) {
        case "1":
            typeName = resource.customerPerformance
            break
        case "2":
            typeName = resource.driverCompliance
            break
        case "3":
            typeName = resource.driverPerformance
            break
        case "4":
            typeName = resource.routeLevelReport
            break
        case "5":
            typeName = resource.stopLevelReport
            break
        default:
            break
    }
    const [format, setFormat] = useState(1)
    const [region, setRegion] = useState(1)
    const [location, setLocation] = useState(resource.all)
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [sortBy, setSortBy] = useState("")
    const [email, setEmail] = useState("")
    const [isCompress, setIsCompress] = useState(true)
    const [sortByList, setSortByList] = useState([]);
    const [locations, setLocations] = useState([]);
    const fetchLocations = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/locations'

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        console.log('data', data)
        setLocations(data.items)
        setLoading(false);
    }
    const [loading, setLoading] = useState(true);
    //validation
    const [validationMsg, setValidationMsg] = useState("");
    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const validateForm = () => {
        if (!from || !to) {
            setValidationMsg(resource.errorReportTime)
            setValidationDialogOpen(true)
            return
        }
        return submit()
    }

    const submit = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/reports';
        var body = {
            type,
            format,
            region,
            locationId: location == "All" ? null : location,
            from,
            to,
            email,
            isCompress,
        };
        console.log('body', body)
        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var blob = await response.blob();
            console.log("blob ", blob);
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'report.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        setLoading(false);
    }
    useEffect(() => {
        fetchLocations();
    }, []);
    return (
        <>
            <SpinnerModal open={loading} />
            <ValidationDialog content={validationMsg} open={validationDialogOpen} setOpen={setValidationDialogOpen} />
            <TitleContainer title={resource.generateReport} />
            <ContentContainer style={{ width: '50%' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="body1">{typeName}</Typography>
                    </Grid>
                    {
                        (type === "1" || type === "2" || type === "3") &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1">{resource.format}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={format}
                                    onChange={(e) => setFormat(e.target.value)}
                                >
                                    <MenuItem value={1}>
                                        <em>{resource.html}</em>
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <em>{resource.pdf}</em>
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </>
                    }
                    {
                        (type === "1" || type === "3") &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1">{resource.region}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                >
                                    <MenuItem value={1}>
                                        <em>{resource.all}</em>
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        <em>{resource.can}</em>
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </>
                    }
                    {
                        (type === "1" || type === "3" || type === "4" || type === "5") &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1">{resource.location}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                    <MenuItem value={resource.all}>
                                        <em>{resource.all}</em>
                                    </MenuItem>
                                    {
                                        locations.map((el, i) => (
                                            <MenuItem key={i} value={el.id}>{el.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                        </>
                    }

                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.from}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField type="date" value={from} onChange={(event) => setFrom(event.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.to}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField type="date" value={to} onChange={(event) => setTo(event.target.value)} />
                    </Grid>
                    {
                        (type === "1" || type === "3") &&
                        <>
                            <Grid item xs={4}>
                                <Typography variant="body1">{resource.sortBy}</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Select
                                    value={sortBy}
                                    onChange={(e) => setSortBy(e.target.value)}
                                >
                                    {
                                        sortByList.map((el, i) => (
                                            <MenuItem key={i} value={el.id}>{el.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>
                        </>
                    }
                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.emailAddress}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.compressZip}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Checkbox
                            className={classes.checkboxRoot}
                            checked={isCompress}
                            onChange={() => setIsCompress(prev => !prev)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{ padding: 11, color: "#007dc0", fontSize: 17 }} onClick={validateForm}>{resource.generate}</Button>
                </Grid>
            </ContentContainer>
        </>
    )
}