import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import ListContainer from '../ListContainer'
import ContentContainer from '../ContentContainer'
import StyledTableCell from '../StyledTableCell'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { SpinnerModal } from '../Modals/SpinnerModal';
import Checkbox from "@mui/material/Checkbox";
import { useParams } from 'react-router';

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },

    checkboxRoot: {
        color: "#134169!important"
    }

});

export function Plans() {
    const resource = global.resources[useTheme().locale];
    const classes = useStyles();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState({
        sessionName: "",
        creationDate: "",
        planCount: "",
        shipmentCount: "",
        distance: "",
        duration: "",
        status: "",
    });
    const [files, setFiles] = useState([]);
    const fetchSession = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/planning/session/' + id
        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });
        if (response.ok) {
            var data = await response.json();
            console.log('data', data)
            //basic info tab
            setSession(data);
            setFiles(data.uploadedFiles);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchSession();
    }, []);

    return (
        <>
            <SpinnerModal open={loading} />
            <TitleContainer title={resource.sessions} />
            <ContentContainer>
                <Typography variant="h6">{resource.sessionName}: {session.sessionName}</Typography>
                <Typography variant="h6">{resource.deliveryDate}: {session.deliveryDate}</Typography>
                <Typography variant="h6">{resource.session}</Typography>
                <ListContainer>
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>{resource.name}</StyledTableCell>
                                    <StyledTableCell>{resource.creationDate}</StyledTableCell>
                                    <StyledTableCell>{resource.shipments}</StyledTableCell>
                                    <StyledTableCell>{resource.routes}</StyledTableCell>
                                    <StyledTableCell>{resource.stops}</StyledTableCell>
                                    <StyledTableCell>{resource.distance}</StyledTableCell>
                                    <StyledTableCell>{resource.duration}</StyledTableCell>
                                    <StyledTableCell>{resource.status}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <Checkbox
                                            checked={session.isSelected}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell><Typography>{session.sessionName}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.creationDate}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.shipmentCount}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.planCount}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.shipmentCount}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.distance}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.duration}</Typography></StyledTableCell>
                                    <StyledTableCell><Typography>{session.status}</Typography></StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Link to={'mapping'}><IconButton size='small' style={{ color: '#134169' }}><FileOpenOutlinedIcon /></IconButton></Link>
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListContainer>
                <Typography variant="h6">{resource.uploadedFiles}</Typography>
                <ListContainer>
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>{resource.user}</StyledTableCell>
                                    <StyledTableCell>{resource.date}</StyledTableCell>
                                    <StyledTableCell>{resource.filename}</StyledTableCell>
                                    <StyledTableCell>{resource.sizebytes}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {files.map((row, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell><Typography>{row.userName}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.date}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.fileName}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.size}</Typography></StyledTableCell>
                                        <StyledTableCell align="right">
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }}><FileCopyOutlinedIcon /></IconButton>
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }}><ModeEditOutlineOutlinedIcon /></IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListContainer>
            </ContentContainer>
        </>
    )
}