import { TableRow, TableCell } from '@material-ui/core';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { withStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles({
    root: {
        color: "#3c5064",
        borderBottomColor: "rgba(84, 84, 88, 0.65)",
        paddingLeft: 0,
        paddingRight: 0
    }
})(TableCell);

const DND_ITEM_TYPE = 'row'

const DraggableRow = ({ index, moveRow, disabled = false, children }) => {
    const dropRef = React.useRef(null)
    const dragRef = React.useRef(null)

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        type: DND_ITEM_TYPE,
        canDrag: !disabled,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    preview(drop(dropRef))
    drag(dragRef)

    return (
        <TableRow ref={dropRef} style={{ opacity }} >
            <StyledTableCell ref={dragRef} style={{ opacity: disabled ? 0.1 : 1, width: 40 }}><MenuOutlinedIcon /></StyledTableCell>
            {/* put table cells here */}
            {children}
        </TableRow>
    )
}


export default DraggableRow;