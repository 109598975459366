import { Typography, Grid, Select, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CustomTextField from '../TextFields/CustomTextField'
import { useParams } from 'react-router';
import { SpinnerModal } from '../Modals/SpinnerModal';
import TitleContainer from '../TitleContainer'
import { useTheme } from '@mui/material/styles';
import ContentContainer from '../ContentContainer'
import TabsViewContainer from '../TabsViewContainer'
import TabContentCard from '../TabContentCard'
import { useHistory } from "react-router-dom";
import ValidationDialog from '../ValidationDialog'

//tab
const BasicInformationTabContent = ({
    id,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    permission,
    setPermission,
    email,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
}) => {
    const resource = global.resources[useTheme().locale];
    return (
        <>
            <TabContentCard title={`${firstName} ${lastName}`}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.firstName}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField value={firstName} onChange={(event) => setFirstName(event.target.value)} />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.lastName}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField value={lastName} onChange={(event) => setLastName(event.target.value)} />
                    </Grid>
                    <Grid item xs={2} style={{ margin: 'auto' }}>
                        <Typography>{resource.role}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Select
                            value={permission}
                            onChange={(e) => setPermission(e.target.value)}
                        >
                            <MenuItem value={1}>
                                <em>{resource.superAdmin}</em>
                            </MenuItem>
                            <MenuItem value={2}>
                                <em>{resource.admin}</em>
                            </MenuItem>
                            <MenuItem value={3}>
                                <em>{resource.staff}</em>
                            </MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </TabContentCard>
            <TabContentCard title={resource.loginDetails}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.email}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField value={email} disabled={id !== "create"} onChange={(event) => setEmail(event.target.value)} />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.password}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField type="password" value={password} onChange={(event) => setPassword(event.target.value)} />
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.confirmPassword}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField type="password" value={passwordConfirm} onChange={(event) => setPasswordConfirm(event.target.value)} />
                    </Grid>

                </Grid>
            </TabContentCard>
        </>
    )
}
export const EditStaff = ({ user }) => {
    const resource = global.resources[useTheme().locale];
    const history = useHistory();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    //basic info tab
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [permission, setPermission] = useState(1);
    const [email, setEmail] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [password, setPassword] = useState("");

    const fetchStaff = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/users/' + id;

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });
        if (response.ok) {
            var data = await response.json();
            console.log('data', data)
            //basic info tab
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmail(data.email);
            setPermission(data.permission)
        }
        setLoading(false);
    }

    const submitStaff = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/users';
        var body = {
            id: (id !== "create" ? id : 0),
            //basic info tab
            firstName,
            lastName,
            permission,
            email,
            registrationPassword: password,
            password,
            passwordConfirm,
        };

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("sumbit staff RESPONSE's data only = ", data);
        }
        setLoading(false);
        history.push('/staff/list')
    }

    const back = () => {
        history.push('/staff/list')
    }

    useEffect(() => {
        if (id !== "create")
            fetchStaff();
        else
            setLoading(false);
    }, [id])

    //validation
    const [validationMsg, setValidationMsg] = useState("");
    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const validateForm = () => {
        if (!firstName || !lastName) {
            setValidationMsg(resource.errorStaffName)
            setValidationDialogOpen(true)
            return
        }
        if ((password || passwordConfirm) && (password !== passwordConfirm)) {
            setValidationMsg(resource.errorStaffPassword)
            setValidationDialogOpen(true)
            return
        }

        return submitStaff()
    }

    //tabs
    const basicTab = {
        label: resource.basicInformation,
        content: <BasicInformationTabContent
            id={id}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            permission={permission}
            setPermission={setPermission}
            password={password}
            setPassword={setPassword}
            passwordConfirm={passwordConfirm}
            setPasswordConfirm={setPasswordConfirm}
        />
    }
    const [tabList, setTabList] = useState([basicTab]);
    useEffect(() => {
        const res = [basicTab]
        setTabList([...res])
    }, [
        resource,
        firstName,
        lastName,
        email,
        permission,
        password,
        passwordConfirm,
    ])
    return (
        <>
            <SpinnerModal open={loading} />
            <ValidationDialog content={validationMsg} open={validationDialogOpen} setOpen={setValidationDialogOpen} />
            <TitleContainer title={id === "create" ? resource.addStaff : resource.editStaff} />
            <ContentContainer>
                <TabsViewContainer tabList={tabList} handleClickCancel={back} handleClickSave={validateForm} />
            </ContentContainer>
        </>
    )
}