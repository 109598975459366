import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';

const styles = {

    containerStyles: {
        display: "flex",
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20,

        "& button": {
            color: "white"
        },
    },

    leftContainer: {
        flexGrow: 1,
        display: "flex",

        "& button": {
            borderColor: "white",
            marginRight: 10
        },

        "& button:hover": {
            backgroundColor: "gray",
            borderColor: "white",
        },
    },

    rightContainer: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "end",
        flexWrap: "wrap"
    },

    paginationRoot: {

        "& button:focus": {
            outline: "none"
        },

        "& button:hover": {
            backgroundColor: "gray"
        },

        "& .Mui-selected": {
            backgroundColor: "#3c5064 !important",
            color: "white"
        },
    },

    sizeButtonRoot: {
        "& button": {
            color: "white",
            minWidth: "unset",
            marginLeft: 20,
            padding: 0
        },

        "& button:hover": {
            backgroundColor: "gray"
        },

        "& button:focus": {
            outline: "none"
        },

        "& button.selected": {
            color: "#F89941"
        },

        display: "flex",
        alignItems: "center"
    },

    view: {
        paddingTop: 2,
    }
}

function PaginationRowContainer(props) {
    const { classes, buttonList = [], isShowSize, size, page, pageTotal, handleChangeSize, handleChangePage, sizeList = [10, 25, 50, 100] } = props;
    const resource = global.resources[useTheme().locale];
    return (
        <Box className={classes.containerStyles}>
            {
                buttonList.length > 0 && (
                    <Box className={classes.leftContainer}>
                        {buttonList.map((el, i) => (
                            <Button key={i} variant="outlined" size="small" onClick={el.handleClick}>{el.text}</Button>
                        ))}
                    </Box>
                )
            }
            <Box className={classes.rightContainer}>
                {
                    isShowSize && (
                        <>
                            <Typography classes={{ root: classes.view }}>{resource.view}: </Typography>
                            <Box classes={{ root: classes.sizeButtonRoot }}>
                                {
                                    sizeList.map((el, index) => (
                                        <Button key={index} className={size === el ? "selected" : ""} variant="text" size="small" onClick={() => handleChangeSize(el)}>{el}</Button>
                                    ))
                                }
                            </Box>
                        </>
                    )
                }
                <Pagination classes={{ root: classes.paginationRoot }} count={pageTotal > 0 ? pageTotal : 1} page={page} onChange={(e, val) => handleChangePage(val)} />
            </Box>
        </Box>
    );
}

export default withStyles(styles)(PaginationRowContainer);