import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import ConfirmDialog from './ConfirmDialog'

const styles = {
    containerStyles: {
        width: '70%',
        margin: 'auto',
        paddingBottom: 20,
        border: '1px solid #c9c9c9',
        borderRadius: 5,
        minWidth: 800,
        textAlign: 'left',
    },

    tabsHeaderContainer: {
        color: "#3c5064",
        display: 'flex',
        justifyContent: "space-between",
        borderBottom: '1px solid #c9c9c9',

        "& button": {
            marginLeft: 10,
        },

        "& button:focus": {
            outline: "none"
        }

    },

    buttonsContainer: {
        display: 'flex',

        "& button": {
            marginLeft: 0,
            borderRadius: 0,
            borderLeft: '1px solid #c9c9c9',
        },

        "& button.saveBtn": {
            color: "#3c5064",
        }
    },

    tabContentContainer: {
        padding: 50
    }
}

//tabs
const TabPanel = withStyles(styles)((props) => {
    const { classes, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={classes.tabContentContainer}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
})

function TabsViewContainer(props) {
    const { classes, children, tabList = [], handleClickCancel, handleClickSave, containerStyle, scrollable = false } = props;
    const resource = global.resources[useTheme().locale];
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const [value, setValue] = React.useState(0);
    const [confirmLeavingDialogOpen, setConfirmLeavingDialogOpen] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className={classes.containerStyles} style={containerStyle}>
            <Box className={classes.tabsHeaderContainer}>
                <Tabs variant={scrollable ? "scrollable" : "standard"} value={value} onChange={handleChange} textColor="inherit" TabIndicatorProps={{ style: { backgroundColor: "#F89941", height: 4 } }} aria-label="basic tabs example">
                    {tabList.map((el, i) => <Tab key={i} label={el.label} {...a11yProps(i)} />)}
                </Tabs>
                <Box className={classes.buttonsContainer}>
                    <Button color="inherit" onClick={() => setConfirmLeavingDialogOpen(true)}>
                        {resource.cancel}
                    </Button>
                    <Button className="saveBtn" onClick={handleClickSave}>
                        {resource.save}
                    </Button>
                </Box>
            </Box>
            <ConfirmDialog content={resource.confirmLeaving} open={confirmLeavingDialogOpen} handleCancel={() => setConfirmLeavingDialogOpen(false)} handleConfirm={handleClickCancel} />
            {tabList.map((el, i) => (
                <TabPanel key={i} value={value} index={i}>
                    {el.content}
                </TabPanel>)
            )}
            {children}
        </Box>
    );
}

export default withStyles(styles)(TabsViewContainer);