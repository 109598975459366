import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, Select, MenuItem, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import ListContainer from '../ListContainer'
import ContentContainer from '../ContentContainer'
import StyledTableCell from '../StyledTableCell'
import { useTheme } from '@mui/material/styles';
import { SpinnerModal } from '../Modals/SpinnerModal';
import Checkbox from "@mui/material/Checkbox";
import { useParams } from 'react-router';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import { arrayMoveImmutable } from 'array-move';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableRow from '../DraggableRow'
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import RemoveIcon from '@mui/icons-material/Remove';
import CustomTextField from '../TextFields/CustomTextField'

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },
});
const RouteRow = (props) => {
    const resource = global.resources[useTheme().locale];
    const colorList = global.colorList
    const { route, index, setRoutes, handleUnrouteClick, updateRouteStopsOrder } = props;
    const [open, setOpen] = React.useState(false);
    const handleRowClick = () => setRoutes(prev => {
        const routeToChange = prev[index]
        routeToChange.isSelected = !routeToChange.isSelected
        if (routeToChange.isSelected)
            prev.filter((el, i) => i != index).forEach(x => x.isSelected = false)
        return [...prev]
    })

    const handleMoveStop = (dragIndex, hoverIndex) => {
        const newStops = arrayMoveImmutable(route.stops, dragIndex, hoverIndex)
        newStops.forEach((el, i) => el.startOrder = i + 1)
        updateRouteStopsOrder(route.id, newStops)
        setRoutes(prev => {
            prev[index].stops = newStops
            return [...prev]
        })
    }

    const handleUnroute = (stopId) => {
        handleUnrouteClick(route.id, stopId)
    }

    return (
        <React.Fragment>
            <TableRow
                style={{ backgroundColor: route.isSelected ? "#d9f2ff" : "" }}
            >
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    <Checkbox
                        checked={route.isShowOnMap}
                        style={{ color: colorList[index] }}
                        onChange={() => setRoutes(prev => {
                            const routeToChange = prev[index]
                            routeToChange.isShowOnMap = !routeToChange.isShowOnMap
                            routeToChange.isSelected = false
                            return [...prev]
                        })}
                    />
                </StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.routeName}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.driver?.name} {route.truck?.name}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.startTime}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.finishTime}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.duration}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.distance}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.startingDepot?.name}</StyledTableCell>
                <StyledTableCell onClick={handleRowClick}>{route.endingDepot?.name}</StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <DndProvider backend={HTML5Backend}>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>{resource.stopNumber}</StyledTableCell>
                                            <StyledTableCell>{resource.customerCustom}</StyledTableCell>
                                            <StyledTableCell>{resource.name}</StyledTableCell>
                                            <StyledTableCell>{resource.address}</StyledTableCell>
                                            <StyledTableCell>{resource.city}</StyledTableCell>
                                            <StyledTableCell>{resource.arrived}</StyledTableCell>
                                            <StyledTableCell>{resource.serviceTime}</StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {route.stops.map((stop, i) => (
                                            <StopRow key={i} index={i}
                                                moveRow={handleMoveStop} stop={stop} handleUnroute={handleUnroute} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </DndProvider>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const StopRow = (props) => {
    const resource = global.resources[useTheme().locale];
    const { stop, index, moveRow, isDisabled, handleUnroute } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <DraggableRow index={index} disabled={isDisabled}
                moveRow={moveRow} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{stop.startOrder}</StyledTableCell>
                <StyledTableCell>{stop.customerNameBrev}</StyledTableCell>
                <StyledTableCell>{stop.customerName}</StyledTableCell>
                <StyledTableCell>{stop.address}</StyledTableCell>
                <StyledTableCell>{stop.shipCity}</StyledTableCell>
                <StyledTableCell>{stop.arrived}</StyledTableCell>
                <StyledTableCell>{stop.serviceTime}</StyledTableCell>
                <StyledTableCell align="right">
                    <IconButton size='small' style={{ color: '#134169' }} onClick={() => handleUnroute(stop.id)}><RemoveIcon /></IconButton>
                </StyledTableCell>
            </DraggableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>{resource.invoiceCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.itemCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.productDesc}</StyledTableCell>
                                        <StyledTableCell>{resource.quantity}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stop.invoices.map((invoice, i) => (
                                        <InvoiceRow key={i} invoice={invoice} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
const UnroutedStopRow = (props) => {
    const resource = global.resources[useTheme().locale];
    const { stop, handleRouteSelectClick, } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{stop.customerNameBrev}</StyledTableCell>
                <StyledTableCell>{stop.customerName}</StyledTableCell>
                <StyledTableCell>{stop.address}</StyledTableCell>
                <StyledTableCell>{stop.shipCity}</StyledTableCell>
                <StyledTableCell>{(stop.timeWindowOneStart || stop.timeWindowOneEnd) ? ((stop.timeWindowOneStart || ' ') + "-" + (stop.timeWindowOneEnd || ' ')) : ''} {(stop.timeWindowTwoStart || stop.timeWindowTwoEnd) ? ((stop.timeWindowTwoStart || ' ') + "-" + (stop.timeWindowTwoEnd || ' ')) : ''}</StyledTableCell>
                <StyledTableCell align="right">
                    <IconButton size='small' style={{ color: '#134169' }} onClick={() => handleRouteSelectClick(true, stop.id)}><AddIcon /></IconButton>
                </StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>{resource.invoiceCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.itemCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.productDesc}</StyledTableCell>
                                        <StyledTableCell>{resource.quantity}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stop.invoices.map((invoice, i) => (
                                        <InvoiceRow key={i} invoice={invoice} />
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const InvoiceRow = (props) => {
    const resource = global.resources[useTheme().locale];
    const { invoice } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell>{invoice.orderNumber}</StyledTableCell>
                <StyledTableCell>{invoice.itemCustom}</StyledTableCell>
                <StyledTableCell>{invoice.productDesc}</StyledTableCell>
                <StyledTableCell>{invoice.quantity}</StyledTableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{resource.orderNumber}</StyledTableCell>
                                        <StyledTableCell>{resource.itemCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.productDesc}</StyledTableCell>
                                        <StyledTableCell>{resource.quantity}</StyledTableCell>
                                        <StyledTableCell>{resource.status}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <StyledTableCell>{invoice.orderNumber}</StyledTableCell>
                                        <StyledTableCell>{invoice.itemCustom}</StyledTableCell>
                                        <StyledTableCell>{invoice.productDesc}</StyledTableCell>
                                        <StyledTableCell>{invoice.quantity}</StyledTableCell>
                                        <StyledTableCell>{invoice.status}</StyledTableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const RouteSelectDialog = (props) => {
    const { onClose, open, routes } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add To Route</DialogTitle>
            <List sx={{ pt: 0 }}>
                {routes.map((route, i) => (
                    <ListItem disableGutters key={i}>
                        <ListItemButton onClick={() => handleListItemClick(route.id)}>
                            <ListItemText primary={route.routeName} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}
const AddRouteDialog = (props) => {
    const {
        onClose,
        open,
        routes,
        drivers,
        trucks,
        newRouteName,
        setNewRouteName,
        newRouteDriverId,
        setNewRouteDriverId,
        newRouteTruckId,
        setNewRouteTruckId,
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add New Route</DialogTitle>
            <List sx={{ pt: 0 }}>
                {routes.map((route, i) => (
                    <ListItem key={i}>
                        <ListItemText primary={route.routeName + " " + (route.driver ? route.driver.name : "") + " " + (route.truck ? route.truck.name : "")} />
                    </ListItem>
                ))}
                <hr />
                <ListItem>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <Typography variant="body1">Route Name</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <CustomTextField value={newRouteName} onChange={(event) => setNewRouteName(event.target.value)} />
                        </Grid>

                        <Grid item xs={3}>
                            <Typography variant="body1">Driver</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Select
                                value={newRouteDriverId}
                                onChange={(e) => setNewRouteDriverId(e.target.value)}
                            >
                                <MenuItem value={""}>
                                    <em>Select Driver</em>
                                </MenuItem>
                                {drivers.map((driver, i) =>
                                    <MenuItem key={i} value={driver.id}>
                                        <em>{driver.name}</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="body1">Truck</Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Select
                                value={newRouteTruckId}
                                onChange={(e) => setNewRouteTruckId(e.target.value)}
                            >
                                <MenuItem value={""}>
                                    <em>Select Truck</em>
                                </MenuItem>
                                {trucks.map((truck, i) =>
                                    <MenuItem key={i} value={truck.id}>
                                        <em>{truck.name}</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </Grid>
                    </Grid>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemClick('addRoute')}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
}

export function Mapping({ selectedLocationId }) {
    const resource = global.resources[useTheme().locale];
    const colorList = global.colorList
    const classes = useStyles();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState({
        sessionName: "",
        creationDate: "",
        planCount: "",
        shipmentCount: "",
        distance: "",
        duration: "",
        status: "",
        locationId: "",
        routedStops: [],
        unroutedStops: [],
    });
    const [routes, setRoutes] = useState([]);
    const fetchSession = async () => {
        setLoading(true);
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/planning/session/' + id
        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });
        if (response.ok) {
            var data = await response.json();
            console.log('data', data)
            setSession(data);
            setRoutes(data.routes);
        }
        setLoading(false);
    }

    const [drivers, setDrivers] = useState([]);
    const fetchDrivers = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/drivers'

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        setDrivers(data.items)
    }

    const [trucks, setTrucks] = useState([]);
    const fetchTrucks = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/trucks'

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        setTrucks(data.items)
    }

    useEffect(() => {
        fetchSession();
        fetchDrivers();
        fetchTrucks();
    }, []);

    const MapContainer = ({ google, routes }) => {
        const stops = routes.reduce((prev, cur) => [...prev, ...cur.stops], [])
        console.log('routes', routes)
        const [showingInfoWindow, setShowingInfoWindow] = useState(false)
        const [activeMarker, setActiveMarker] = useState({
            customer: "",
            name: "",
            address: "",
            city: "",
        })
        const onMarkerClick = (props, marker) => {
            setShowingInfoWindow(true);
            setActiveMarker(marker)
        }

        const onMapClicked = (props) => {
            setShowingInfoWindow(false);
            setActiveMarker({
                customer: "",
                name: "",
                address: "",
                city: "",
            })
        }
        return (
            <Map
                google={google}
                zoom={11}
                containerStyle={{
                    position: 'relative',
                    height: 500
                }}
                initialCenter={{
                    lat: 53.54499944898237,
                    lng: -113.50101416464348
                }}
                onClick={onMapClicked}
            >
                {
                    routes.map((route, i) => {
                        const coords = route.stops.sort((a, b) => a.startOrder - b.startOrder).map(stop => ({ lat: stop.lat, lng: stop.lng }))
                        const color = route.isSelected ? "#f20089" : colorList[i]
                        const opacity = route.isSelected ? 1 : 0.8
                        const weight = route.isSelected ? 4 : 2
                        return <Polyline
                            key={i}
                            path={coords}
                            options={{ strokeOpacity: opacity, strokeColor: color, fillColor: color, strokeWeight: weight }}
                        />
                    })
                }
                {
                    stops.map((stop, i) => (
                        <Marker
                            key={i}
                            position={{ lat: stop.lat, lng: stop.lng }}
                            onClick={onMarkerClick}
                            customer={stop.customerNameBrev}
                            name={stop.customerName}
                            address={stop.address}
                            city={stop.city}
                            label={{ text: stop.startOrder.toString(), fontSize: "12px" }}
                            icon={{
                                url: require("../../assets/icon/marker.png"),
                                labelOrigin: new google.maps.Point(17, 13),
                            }}
                        />
                    ))
                }
                <InfoWindow
                    marker={activeMarker}
                    visible={showingInfoWindow}
                >
                    <div>
                        <div><span>{resource.customer}: </span><span>{activeMarker.customer}</span></div>
                        <div><span>{resource.name}: </span><span>{activeMarker.name}</span></div>
                        <div><span>{resource.address}: </span><span>{activeMarker.address}</span></div>
                        <div><span>{resource.city}: </span><span>{activeMarker.city}</span></div>
                    </div>
                </InfoWindow>

            </Map>
        );
    }

    const MapComponent = GoogleApiWrapper({
        apiKey: "AIzaSyCRHKJFdNSHYXTBJKUetJihowfFyp3SRKo"
    })(MapContainer)

    const [routeSelectOpen, setRouteSelectOpen] = React.useState(false);
    const [selectedStopId, setSelectedStopId] = React.useState();

    const handleRouteSelectClose = (routeId) => {
        setRouteSelectOpen(false);
        if (routeId && selectedStopId) {
            const selectedStopIndex = session.unroutedStops.findIndex(x => x.id == selectedStopId)
            const selectedStop = session.unroutedStops[selectedStopIndex]
            const selectedRoute = routes.find(x => x.id == routeId)

            setSession(prev => {
                prev.unroutedStops.splice(selectedStopIndex, 1)
                return { ...prev }
            })
            setRoutes(prev => {
                selectedStop.startOrder = selectedRoute.stops.length + 1;
                selectedRoute.stops.push(selectedStop)
                return [...prev]
            })
            addStopToRoute(routeId, selectedStopId, selectedRoute.stops.length + 1)
        }
    };
    const handleRouteSelectClick = (open, stopId) => {
        setRouteSelectOpen(open);
        setSelectedStopId(stopId)
    };

    const addStopToRoute = async (routeId, stopId, startOrder) => {
        var url = global.rootUrl + 'api/planning/AddStopToRoute';
        var body = {
            routeId,
            stopId,
            startOrder: startOrder
        };

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("addStopToRoute data = ", data);
        }
    }
    const handleUnrouteClick = (routeId, stopId) => {
        const selectedRoute = routes.find(x => x.id == routeId)
        const selectedStopIndex = selectedRoute.stops.findIndex(x => x.id == stopId)
        const selectedStop = selectedRoute.stops[selectedStopIndex]

        selectedRoute.stops.splice(selectedStopIndex, 1)
        selectedRoute.stops.forEach((el, i) => el.startOrder = i + 1)

        setSession(prev => {
            prev.unroutedStops.unshift(selectedStop)
            return { ...prev }
        })
        setRoutes([...routes])
        unrouteStop(stopId, () => updateRouteStopsOrder(routeId, selectedRoute.stops))
    }
    const unrouteStop = async (stopId, handleSuccess) => {
        var url = global.rootUrl + 'api/planning/UnrouteStop';
        var body = {
            stopId,
        };

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("unrouteStop data = ", data);
            handleSuccess()
        }
    }

    const updateRouteStopsOrder = async (routeId, stops) => {
        var url = global.rootUrl + 'api/planning/UpdateRouteStopsOrder';
        var body = {
            routeId: routeId,
            stopOrders: stops.map(el => ({ stopId: el.id, startOrder: el.startOrder })),
        };

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("updateRouteStopsOrder data = ", data);
        }
    }

    const [newRouteOpen, setNewRouteOpen] = React.useState(false);
    const [newRouteName, setNewRouteName] = React.useState("");
    const [newRouteDriverId, setNewRouteDriverId] = React.useState("");
    const [newRouteTruckId, setNewRouteTruckId] = React.useState("");

    const handleNewRouteClose = (value) => {
        setNewRouteOpen(false);
        if (newRouteName && value == 'addRoute') {
            const newRoute = {
                id: 0,
                routeName: newRouteName,
                startingDepotId: session.locationId,
                endingDepotId: session.locationId,
                sessionId: session.id,
                driverId: newRouteDriverId,
                truckId: newRouteTruckId
            }
            addRoute(newRoute, fetchSession)
        }
    };
    const handleNewRouteClick = () => {
        setNewRouteOpen(true);
        setNewRouteName("")
        setNewRouteDriverId("")
        setNewRouteTruckId("")
    };
    const addRoute = async (newRoute, handleSuccess) => {
        var url = global.rootUrl + 'api/planning/AddRoute';
        var body = newRoute;

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("addRoute data = ", data);
            handleSuccess()
        }
    }
    return (
        <>
            <SpinnerModal open={loading} />
            <TitleContainer title={resource.sessions} />
            <ContentContainer style={{ margin: 0, padding: 0 }}>
                <ContentContainer style={{ display: 'flex', height: 500, margin: 0, padding: 0 }}>
                    <div style={{ width: '60%', height: '100%', overflow: 'auto' }}>
                        <Button size='small' style={{ color: '#134169', float: 'right', marginRight: 20 }} onClick={handleNewRouteClick}><AddIcon /> Add Route</Button>
                        <AddRouteDialog
                            open={newRouteOpen}
                            onClose={handleNewRouteClose}
                            routes={routes}
                            newRouteName={newRouteName}
                            setNewRouteName={setNewRouteName}
                            drivers={drivers}
                            trucks={trucks }
                            newRouteDriverId={newRouteDriverId}
                            setNewRouteDriverId={setNewRouteDriverId}
                            newRouteTruckId={newRouteTruckId}
                            setNewRouteTruckId={setNewRouteTruckId}
                        />
                        <ListContainer>
                            <TableContainer className={classes.table}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell>{resource.show}</StyledTableCell>
                                            <StyledTableCell>{resource.route}</StyledTableCell>
                                            <StyledTableCell>{resource.vehicle}</StyledTableCell>
                                            <StyledTableCell>{resource.startTime}</StyledTableCell>
                                            <StyledTableCell>{resource.finishTime}</StyledTableCell>
                                            <StyledTableCell>{resource.duration}</StyledTableCell>
                                            <StyledTableCell>{resource.distance}</StyledTableCell>
                                            <StyledTableCell>{resource.startingDepot}</StyledTableCell>
                                            <StyledTableCell>{resource.endingDepot}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {routes.map((route, i) => (
                                            <RouteRow
                                                key={i}
                                                route={route}
                                                index={i}
                                                setRoutes={setRoutes}
                                                handleUnrouteClick={handleUnrouteClick}
                                                updateRouteStopsOrder={updateRouteStopsOrder}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </ListContainer>
                    </div>
                    <div style={{ height: '100%', width: '40%' }}>
                        <MapComponent routes={routes.filter(x => x.isShowOnMap)} />
                    </div>
                </ContentContainer>
                <ContentContainer style={{ margin: 0, padding: 0 }}>
                    <ListContainer>
                        <TableContainer className={classes.table}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell>{resource.customerCustom}</StyledTableCell>
                                        <StyledTableCell>{resource.name}</StyledTableCell>
                                        <StyledTableCell>{resource.address}</StyledTableCell>
                                        <StyledTableCell>{resource.city}</StyledTableCell>
                                        <StyledTableCell>{resource.timeWindow}</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {session.unroutedStops.map((stop, i) => (
                                        <UnroutedStopRow key={i} stop={stop} handleRouteSelectClick={handleRouteSelectClick} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </ListContainer>
                    <RouteSelectDialog
                        open={routeSelectOpen}
                        onClose={handleRouteSelectClose}
                        routes={routes}
                    />
                </ContentContainer>
            </ContentContainer>
        </>
    )
}