import React from 'react';
import { CircularProgress, Modal, Paper } from '@material-ui/core';

export const SpinnerModal = ({ open }) => {
    return (
        <Modal
            open={open}
            disableAutoFocus={true}
            BackdropProps={{ className: "blur-filter" }}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Paper className="dark-modal" elevation={0} style={{ padding: 28, paddingBottom: 22 }}>
                <CircularProgress className="orange-color" />
            </Paper>
        </Modal>
    );
}