import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Box, Typography, Button } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = {

    input: {
        "& :: placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(235, 235, 245, 0.6)',
            opacity: 1 /* Firefox */
        },

        "& : -ms - input - placeholder": {
            /* Internet Explorer 10-11 */
            color: 'rgba(235, 235, 245, 0.6)'
        },

        "& :: -ms - input - placeholder": {
            /* Microsoft Edge */
            color: 'rgba(235, 235, 245, 0.6)'
        },
        color: '#FFF',
        paddingTop: 12,
        paddingBottom: 12,
        background: "none !important"
    },

    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },

    root: {
        "& svg": {
            color: 'rgba(235, 235, 245, 0.6) !important',
        },
        backgroundColor: '#00000000 !important'
    },

    containerStyles: {
        display: "flex",
        backgroundColor: "#3c5064",
        justifyContent: "space-between",
        marginLeft: 20,
        marginRight: 20,
        padding: 20
    },

    searchContainer: {
        display: "flex",
        minWidth: "33%",
        flexGrow: 1,
    },

    filtersContainer: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "end",
        flexWrap: "wrap"
    },

    filterContainer: {
        display: "flex",
        marginLeft: 20,
        alignItems: "center",
        color: "white"
    },

    filterSelectRoot: {
        color: "white",
        paddingTop: 6,
        paddingBottom: 6
    },

    filterSelectIcon: {
        color: "white"
    },

    filterSelect: {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "white !important"
        },

        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray !important",
        }
    }
}

function FilterRowContainer(props) {
    const { classes, onChange, value, hasReset = false, handleReset, filters = [], filterList = [], handleFiltersChange } = props;
    const { containerStyles, searchContainer, filtersContainer, filterContainer, filterSelectRoot, filterSelectIcon, filterSelect, ...otherClasses } = classes;
    const resource = global.resources[useTheme().locale];
    return (
        <Box className={containerStyles}>
            <Box className={searchContainer}>
                <TextField
                    variant="filled"
                    hiddenLabel={true}
                    style={{
                        backgroundColor: 'rgba(118, 118, 128, 0.24)',
                        border: 0,
                        borderRadius: 10,
                        flexGrow: 1,
                        marginRight: 15
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                        classes: otherClasses
                    }}
                    onChange={onChange}
                    value={value}
                    placeholder={resource.search}
                    type="text"
                />
                {
                    hasReset &&
                    <Button variant="outlined" style={{ color: "#F89941", borderColor: "#F89941" }} onClick={handleReset}>{resource.reset}</Button>
                }
            </Box>
            <Box className={filtersContainer}>
                {
                    filterList.map((el, i) => (
                        <Box key={i} className={filterContainer}>
                            <Typography style={{ marginRight: 10 }}>{el.title}: </Typography>
                            <FormControl variant="outlined" classes={{ root: filterSelect }}>
                                <Select
                                    value={filters.find(x => x.id === el.id).currentValue}
                                    onChange={(e) => handleFiltersChange(i, e.target.value)}
                                    displayEmpty
                                    classes={{ root: filterSelectRoot, icon: filterSelectIcon }}
                                >
                                    <MenuItem value="">
                                        <em>{resource.showAll}</em>
                                    </MenuItem>
                                    {
                                        el.options.map(option => (
                                            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>    
                    ))
                }
            </Box>
        </Box>
    );
}

export default withStyles(styles)(FilterRowContainer);