import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow, Typography, Grid, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import ListContainer from '../ListContainer'
import ContentContainer from '../ContentContainer'
import StyledTableCell from '../StyledTableCell'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { SpinnerModal } from '../Modals/SpinnerModal';
import Checkbox from "@mui/material/Checkbox";
import CustomTextField from '../TextFields/CustomTextField'

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },

    checkboxRoot: {
        color: "#134169!important"
    }

});

export function Sessions({ selectedLocationId }) {
    const resource = global.resources[useTheme().locale];
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState("");
    const [title, setTitle] = useState(resource.tenMostRecentSessions);
    const [sessions, setSessions] = useState([]);
    const [isVerified, setIsVerified] = useState(false)
    const fetchSessions = async (isDate) => {
        if (selectedLocationId) {
            //const token = await authService.getAccessToken(); 
            var url = global.rootUrl + 'api/planning/sessions?locationId=' + selectedLocationId
            if (isDate) {
                url += "&deliveryDate=" + date
                setTitle(`${resource.sessionForDate}: ${date}`)
            }
            else {
                url += "&size=10"
                setTitle(resource.tenMostRecentSessions)
            }
            var response = await fetch(url, {
                //TODO: include token usage if necessary
                //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
                headers: {}
            });

            var data = await response.json();
            console.log('data', data)
            setSessions(data.items)
        }
        setLoading(false);
    }
    const handleDelete = () => {
        console.log('handleDelete to do')
    }
    useEffect(() => {
        fetchSessions(false);
    }, [selectedLocationId]);

    return (
        <>
            <SpinnerModal open={loading} />
            <TitleContainer title={resource.sessions} />
            <ContentContainer>
                <Typography variant="h6">{title}</Typography>
                <Button variant="contained" onClick={() => fetchSessions(false)}>{resource.showMySessions}</Button>
                <ListContainer>
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>{resource.userName}</StyledTableCell>
                                    <StyledTableCell>{resource.deliveryDate}</StyledTableCell>
                                    <StyledTableCell>{resource.sessionName}</StyledTableCell>
                                    <StyledTableCell>{resource.shipmentCount}</StyledTableCell>
                                    <StyledTableCell>{resource.planCount}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sessions.map((row, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell component="th" scope="row">
                                            <Checkbox
                                                checked={row.isSelected}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <Typography>{row.userName}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell><Typography>{row.deliveryDate}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.sessionName}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.shipmentCount}</Typography></StyledTableCell>
                                        <StyledTableCell><Typography>{row.planCount}</Typography></StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Link to={'session/' + row.id + '/plans'}><IconButton size='small' style={{ color: '#134169' }}><FileOpenOutlinedIcon /></IconButton></Link>
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }}><FileCopyOutlinedIcon /></IconButton>
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }}><ModeEditOutlineOutlinedIcon /></IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListContainer>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={1}>
                        <Checkbox
                            checked={isVerified}
                            onChange={() => setIsVerified(prev => !prev)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="body1">{resource.iHaveVerified}</Typography>
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={handleDelete}>{resource.delete}</Button>
                <hr />
                <Typography variant="h6">{resource.findPlanSessions}</Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.chooseDate}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <CustomTextField type="date" value={date} onChange={(event) => setDate(event.target.value)} />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={() => fetchSessions(true)}>{resource.go}</Button>
                    </Grid>
                </Grid>

            </ContentContainer>
        </>
    )
}