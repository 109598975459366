import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import CustomTooltips from './CustomTooltips'

const styles = {
    containerStyles: {
        width: '100%',
        maxWidth: 700,
        borderRadius: 5,
        boxShadow: '0 0 10px #dddddd',
        margin: 'auto',
        padding: 20,

        "&+&": {
            marginTop: 40,
        }
    },

    headerContainer: {
        color: "#3c5064",
        display: 'flex',
        justifyContent: "space-between",
    },

    divider: {
        borderColor: "#c9c9c9!important",
        marginTop: '20px!important',
        marginBottom: '20px!important'
    },
}

function TabContentCard(props) {
    const { classes, children, title, hasTooltips = false, tootipsText = "", hasSubTitle = false, subTitleText = "", hasTitleRightSection = false, hasTitle = true, titleRightComponent, containerStyle } = props;

    return (
        <Box className={classes.containerStyles} style={containerStyle}>
            {
                hasTitle && (
                    <>
                        <Box className={classes.headerContainer}>
                            <Box>
                                {
                                    hasTooltips
                                        ?
                                        <Typography>{title}<CustomTooltips tootipsText={tootipsText} /></Typography>
                                        :
                                        <React.Fragment>
                                            <Typography>{title}</Typography>
                                            {hasSubTitle && <Typography style={{ fontSize: 12, color: '#3c5064' }}>{subTitleText}</Typography>}
                                        </React.Fragment>
                                }
                            </Box>
                            {
                                hasTitleRightSection &&
                                <Box className={classes.headerRight}>
                                    {titleRightComponent}
                                </Box>
                            }
                        </Box>
                        <Divider classes={{ root: classes.divider }} />
                    </>
                )
            }
            <Box className={classes.content}>
                {children}
            </Box>
        </Box>
    );
}

export default withStyles(styles)(TabContentCard);