import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';
import { AppBar, IconButton, Toolbar, Typography, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTheme } from '@mui/material/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const styles = {
    menuIconRoot: {
        color: 'white',
        minWidth: 30
    },

    menuTextRoot: {
        "& .MuiTypography-root": {
            fontSize: 12
        }
    },

    floatMenu: {
        width: 200,
        position: "absolute",
        left: 56,
        zIndex: 100,
        backgroundColor: "#1e1e1e"
    },
}

const NavMenu = ({ classes, language, localeList, locationList, handleLocaleChange, user = {}, selectedLocation, handleLocationChange }) => {
    let location = useLocation();

    const resource = global.resources[useTheme().locale];
    //header nav location, language and user settings 
    const [anchorLocationEl, setLocationAnchorEl] = React.useState(null);
    const locationOpen = Boolean(anchorLocationEl);
    const handleLocationClick = (event) => {
        setLocationAnchorEl(event.currentTarget);
    };
    const handleLocationClose = () => {
        setLocationAnchorEl(null);
    };

    const [anchorLanguageEl, setLanguageAnchorEl] = React.useState(null);
    const languageOpen = Boolean(anchorLanguageEl);
    const handleLanguageClick = (event) => {
        setLanguageAnchorEl(event.currentTarget);
    };
    const handleLanguageClose = () => {
        setLanguageAnchorEl(null);
    };

    const [anchorAccountEl, setAccountAnchorEl] = React.useState(null);
    const accountOpen = Boolean(anchorAccountEl);
    const handleAccountClick = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleAccountClose = () => {
        setAccountAnchorEl(null);
    };

    const logout = async () => {
        var url = global.rootUrl + 'api/auth/unauthenticate';
        var myHeaders = new Headers();

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: myHeaders,
        });
        console.log("LOGOUT ==> response = ", response);
        if (response.status === 200) {
            window.location = location.state ? location.state.returnUrl : "/login";
        } else {
            console.log("NOT LOGGED out")
        }
    }
    //side nav
    const [openNav, setOpenNav] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpenNav(true);
    };

    const handleDrawerClose = () => {
        setOpenNav(false);
    };

    //menu item
    const hasChildren = (item) => {
        const { items: children } = item;

        if (children === undefined) {
            return false;
        }

        if (children.constructor !== Array) {
            return false;
        }

        if (children.length === 0) {
            return false;
        }

        return true;
    }
    const NavMenuItem = ({ item, openNav }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} openNav={openNav} />;
    };

    const SingleLevel = ({ item, openNav }) => {
        const path = useLocation().pathname
        const [active, setActive] = React.useState(path.startsWith(item.to));
        const [open, setOpen] = React.useState(openNav && path.startsWith(item.to));
        React.useEffect(() => {
            setActive(path.startsWith(item.to))
        }, [path, item.to])
        return (
            <Box style={{ display: (!openNav && item.icon) ? "flex" : "block" }} onMouseLeave={!openNav ? () => setOpen(false) : () => { }}>
                <Link className={"text-white no-underline"} to={item.to}>
                    <ListItem button className={active ? (item.icon ? "bg-sidemenu-active" : "selected-nav") : ""} style={{ paddingRight: (!openNav && item.icon) ? 8 : 16 }}>
                        {(openNav || item.icon) && <ListItemIcon classes={{ root: classes.menuIconRoot }} onMouseEnter={!openNav ? () => setOpen(true) : () => { }}>{item.icon}</ListItemIcon>}
                        {(openNav || !item.icon) && <ListItemText classes={{ root: classes.menuTextRoot }} primary={item.title} />}
                    </ListItem>
                </Link>
                {!openNav && (
                    <Collapse in={open} timeout={0} unmountOnExit classes={{ container: !openNav ? classes.floatMenu : {} }}>
                        <Link className={"text-white no-underline"} to={item.to}>
                            <ListItem button className="bg-sidemenu-active">
                                <ListItemText classes={{ root: classes.menuTextRoot }} primary={item.title} />
                            </ListItem>
                        </Link>
                    </Collapse>
                )}
            </Box>
        );
    };

    const MultiLevel = ({ item, openNav }) => {
        const { items: children } = item;
        const path = useLocation().pathname
        const [active, setActive] = React.useState(item.items.findIndex(el => path.startsWith(el.to)) !== -1);
        const [open, setOpen] = React.useState(openNav && item.items.flatMap(x => x.items ? x.items.flatMap(y => y.items ? y.items.flatMap(z => z.to) : y.to) : x.to).findIndex(el => path.startsWith(el)) !== -1);//three layers most for now

        React.useEffect(() => {
            setActive(() => {
                const allTo = []
                const getItemTo = item => {
                    if (!item.items) {
                        allTo.push(item.to)
                    } else {
                        item.items.forEach(el => getItemTo(el))
                    }
                }
                item.items.forEach(el => getItemTo(el))
                return allTo.findIndex(el => path.startsWith(el)) !== -1
            })
        }, [path, item.items])

        const handleClick = () => {
            setOpen((prev) => !prev);
        };

        return (
            <Box style={{ display: !openNav ? "flex" : "block" }} onMouseLeave={!openNav ? () => setOpen(false) : () => { }}>
                <ListItem button onClick={handleClick} className={(active ? "bg-sidemenu-active" : "")}>
                    <ListItemIcon classes={{ root: classes.menuIconRoot }} onMouseEnter={!openNav ? () => setOpen(true) : () => { }}>{item.icon}</ListItemIcon>
                    {openNav && (
                        <>
                            <ListItemText classes={{ root: classes.menuTextRoot }} className={"text-white"} primary={item.title} />
                            {open ? <ExpandMoreIcon className={"text-white"} /> : <ChevronLeftIcon className={"text-white"} />}
                        </>
                    )}
                </ListItem>
                <Collapse in={open} timeout={!openNav ? 0 : "auto"} unmountOnExit classes={{ container: !openNav ? classes.floatMenu : "" }}>
                    {!openNav && (
                        <Box className={"text-white no-underline"}>
                            <ListItem button className="bg-sidemenu-active">
                                <ListItemText classes={{ root: classes.menuTextRoot }} primary={item.title} />
                            </ListItem>
                        </Box>

                    )}
                    <List component="div" disablePadding>
                        {children.map((child, key) => (
                            <NavMenuItem key={key} item={child} openNav={(child.items && !child.to && !openNav) || openNav} />
                        ))}
                    </List>
                </Collapse>
            </Box>
        );
    };

    const menu = [
        {
            icon: <HomeOutlinedIcon />,
            title: resource.home,
            to: "/home",
        },
        {
            icon: <AssessmentOutlinedIcon />,
            title: resource.planning,
            items: [
                {
                    title: resource.sessions,
                    to: "/planning/sessions"
                },
                {
                    title: resource.uploadFile,
                    to: "/planning/uploadfile"
                },
            ]
        },
        {
            icon: <AccountCircleOutlinedIcon />,
            title: resource.deliveries,
            items: [
                {
                    title: resource.dispatch,
                    to: "/deliveries/dispatch"
                },
                {
                    title: resource.mapView,
                    to: "/deliveries/mapview"
                },
                {
                    title: resource.viewOrders,
                    to: "/deliveries/vieworders"
                },
                {
                    title: resource.viewRoutes,
                    to: "/deliveries/viewroutes"
                },
                {
                    title: resource.viewDeliveries,
                    to: "/deliveries/viewdeliveries"
                },
                {
                    title: resource.viewDeliveryDays,
                    to: "/deliveries/viewdeliverydays"
                },
            ]
        },
        {
            icon: <HomeOutlinedIcon />,
            title: resource.orderManagement,
            to: "/ordermanagement",
        },
        {
            icon: <HomeOutlinedIcon />,
            title: resource.reports,
            items: [
                {
                    title: resource.customerPerformance,
                    to: "/report/1"
                },
                {
                    title: resource.driverCompliance,
                    to: "/report/2"
                },
                {
                    title: resource.driverPerformance,
                    to: "/report/3"
                },
                {
                    title: resource.routeLevelReport,
                    to: "/report/4"
                },
                {
                    title: resource.stopLevelReport,
                    to: "/report/5"
                },
            ]
        },
        {
            icon: <AccountCircleOutlinedIcon />,
            title: resource.customerManagement,
            items: [
                {
                    title: resource.add,
                    to: "/customermanagement/add"
                },
                {
                    title: resource.search,
                    to: "/customermanagement/search"
                },
                {
                    title: resource.ungeocoded,
                    to: "/customermanagement/ungeocoded"
                },
                {
                    title: resource.geocode,
                    to: "/customermanagement/geocode"
                },
                {
                    title: resource.uploadCustomers,
                    to: "/customermanagement/uploadcustomers"
                },
            ]
        },
        {
            icon: <ManageAccountsOutlinedIcon />,
            title: resource.staff,
            to: "/staff/list"
        },
        {
            icon: <ManageAccountsOutlinedIcon />,
            title: resource.location,
            to: "/location/list"
        },
        {
            icon: <ManageAccountsOutlinedIcon />,
            title: resource.driver,
            to: "/driver/list"
        },
        {
            icon: <ManageAccountsOutlinedIcon />,
            title: resource.truck,
            to: "/truck/list"
        },
    ];
    return (
        <>
            <AppBar position="fixed" elevation={0} className={"bb-grey bg-top-bar"} style={{ zIndex: 1250 }}>
                <Toolbar className="toolbar-size" style={{ display: "flex", justifyContent: "space-between", minHeight: "unset", paddingLeft: openNav ? 24 : 4 }}>
                    <div style={{ display: openNav ? "flex" : "none", alignItems: "center" }}>
                        <Link to="/">
                            <Typography variant="h6" className="text-white">JDfood</Typography>
                        </Link>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            style={{ outline: "none" }}
                            onClick={handleDrawerClose}
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    {!openNav && (
                        <IconButton
                            color="inherit"
                            aria-label="close drawer"
                            style={{ outline: "none" }}
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {/*language selector*/}
                        {/*<Button*/}
                        {/*    style={{ color: "white", outline: "none" }}*/}
                        {/*    id="basic-button"*/}
                        {/*    aria-haspopup="true"*/}
                        {/*    onClick={handleLanguageClick}*/}
                        {/*    endIcon={<KeyboardArrowDownIcon />}*/}
                        {/*>*/}
                        {/*    {language}*/}
                        {/*</Button>*/}
                        {/*<Menu*/}
                        {/*    id="basic-menu"*/}
                        {/*    open={languageOpen}*/}
                        {/*    anchorEl={anchorLanguageEl}*/}
                        {/*    onClose={handleLanguageClose}*/}
                        {/*    MenuListProps={{*/}
                        {/*        'aria-labelledby': 'basic-button',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {*/}
                        {/*        localeList.map((el, i) => (*/}
                        {/*            <MenuItem key={i} onClick={(e) => { handleLanguageClose(e); handleLocaleChange(el.value) }}>{el.title}</MenuItem>*/}
                        {/*        ))*/}
                        {/*    }*/}
                        {/*</Menu>*/}
                        {/*location selector*/}
                        <Button
                            style={{ color: "white", outline: "none" }}
                            id="basic-button"
                            aria-haspopup="true"
                            onClick={handleLocationClick}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            {selectedLocation}
                        </Button>
                        <Menu
                            id="basic-menu"
                            open={locationOpen}
                            anchorEl={anchorLocationEl}
                            onClose={handleLocationClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {
                                locationList.map((el, i) => (
                                    <MenuItem key={i} onClick={(e) => { handleLocationClose(e); handleLocationChange(el.id) }}>{el.name}</MenuItem>
                                ))
                            }
                        </Menu>
                        {/*account setting*/}
                        {
                            user.isLoggedIn && (
                                <>
                                    <Button
                                        style={{ color: "white", outline: "none" }}
                                        id="basic-button"
                                        aria-haspopup="true"
                                        onClick={handleAccountClick}
                                        endIcon={<KeyboardArrowDownIcon />}
                                    >
                                        {user.fullName}
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        open={accountOpen}
                                        anchorEl={anchorAccountEl}
                                        onClose={handleAccountClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {/*<MenuItem onClick={handleAccountClose}>*/}
                                        {/*    <KeyIcon fontSize="small" />*/}
                                        {/*    <ListItemText style={{ paddingLeft: 10 }}>Change Password</ListItemText>*/}
                                        {/*</MenuItem>*/}
                                        {/*<MenuItem onClick={handleAccountClose}>*/}
                                        {/*    <DoNotDisturbIcon fontSize="small" />*/}
                                        {/*    <ListItemText style={{ paddingLeft: 10 }}>Forget this device</ListItemText>*/}
                                        {/*</MenuItem>*/}
                                        <MenuItem onClick={() => { handleAccountClose(); logout() }}>
                                            <LogoutIcon fontSize="small" />
                                            <ListItemText style={{ paddingLeft: 10 }}>Logout</ListItemText>
                                        </MenuItem>
                                    </Menu>

                                </>
                            )
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Box
                className={`drawer-width br-grey bg-sidemenu ${!openNav && "collapsed"}`}
            >
                <Box style={{ paddingTop: 46, paddingBottom: 33 }}>
                    {menu.map((item, key) => <NavMenuItem key={key} item={item} openNav={openNav} />)}
                </Box>
            </Box>
        </>
    );
}

export default withStyles(styles)(NavMenu);