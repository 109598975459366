import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: {
        "& :: placeholder": {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: 'rgba(235, 235, 245, 0.6)',
            opacity: 1 /* Firefox */
        },

        "& : -ms - input - placeholder": {
            /* Internet Explorer 10-11 */
            color: 'rgba(235, 235, 245, 0.6)'
        },

        "& :: -ms - input - placeholder": {
            /* Microsoft Edge */
            color: 'rgba(235, 235, 245, 0.6)'
        },
        color: '#3c5064',
        paddingTop: 12,
        paddingBottom: 12,
        background: "none !important"
    },

    underline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },

    root: {
        "& svg": {
            color: 'rgba(235, 235, 245, 0.6) !important',
        },
        backgroundColor: '#00000000 !important',

        "&.MuiFilledInput-multiline": {
            paddingTop: 0,
            paddingBottom: 0,
        }
    }

})

function CustomTextField(props) {
    const resource = global.resources[useTheme().locale];
    const { iconComponent, onChange, value, placeholder = resource.enterText, type, passedParams, inputProps, minAmount = 0, maxAmount = 99999999, disabled = false, multiline = false } = props;
    const classes = useStyles();
    return (
        <TextField
            variant="filled"
            hiddenLabel={true}
            {...passedParams}
            style={{
                backgroundColor: 'rgba(118, 118, 128, 0.24)',
                width: '100%',
                border: 0,
                borderRadius: 10,
                opacity: disabled ? 0.4 : 1,
            }}
            InputProps={{
                ...passedParams,
                classes,
                startAdornment: !!iconComponent ? <InputAdornment position="start">{iconComponent}</InputAdornment> : null,
                inputProps: !!type && type === "number" ? { min: minAmount, max: maxAmount, ...inputProps } : { ...inputProps }
            }}
            onChange={onChange}
            value={value}
            placeholder={type === "number" && placeholder === resource.enterText ? "" : placeholder}
            type={!!type ? type : "text"}
            disabled={disabled}
            multiline={multiline}
        />
    );
}

export default CustomTextField;