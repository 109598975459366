import { CssBaseline } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import NavMenu from './NavMenu';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export function Layout({ children, user, handleLocationChange, selectedLocationId }) {
    const [locale, setLocale] = React.useState('enUS');
    const localeList = global.localeList
    const themeLocale = React.useMemo(
        () => createTheme({
            locale
        }),
        [locale],
    );
    const [selectedLocation, setSelectedLocation] = useState("");
    const [locations, setLocations] = useState([]);
    const fetchLocations = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/locations'

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        console.log('data', data)
        setLocations(data.items)
        var selectedLocation = selectedLocationId ? data.items.find(el => el.id === selectedLocationId) : data.items[0]
        setSelectedLocation(selectedLocation.name)
        handleLocationChange(selectedLocation.id)
    }
    const handleSelectedLocationChange = (id) => {
        handleLocationChange(id)
        setSelectedLocation(locations.find(el => el.id === id).name)
    }
    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <div style={{ display: 'flex', minHeight: '100%' }}>
            <CssBaseline />
            <ThemeProvider theme={themeLocale}>
                <NavMenu language={localeList.find(el => el.value === locale).title} localeList={localeList} handleLocaleChange={setLocale} user={user} selectedLocation={selectedLocation} locationList={locations} handleLocationChange={handleSelectedLocationChange} />
                <main style={{ flexGrow: 1, paddingTop: 46, paddingBottom: 33, backgroundColor: "#f5f8fa" }}>
                    {children}
                </main>
            </ThemeProvider>
        </div>
    );
}