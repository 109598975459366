import React from 'react';
import { Box, Container, Paper, Typography } from '@material-ui/core';
import { Redirect } from 'react-router';

export const Home = ({ isLoggedIn }) => {
    return (
        <>
            { isLoggedIn === false ? <Redirect to={{ pathname: '/login', state: { returnUrl: '/' } }} /> : null}
            <Container>
                <Paper>
                    <Box m={3}>
                        <Typography variant="h3">JDfood Home</Typography>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}
