import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';

export default function ConfirmDialog({ content, open = false, handleCancel, handleConfirm }) {
    const resource = global.resources[useTheme().locale];
    return (
        <div>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth={"xs"}
            >
                <DialogTitle>
                    {content}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>{resource.cancel}</Button>
                    <Button onClick={handleConfirm}>
                        {resource.confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}