import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import PaginationRowContainer from '../PaginationRowContainer'
import ListContainer from '../ListContainer'
import ContentContainer from '../ContentContainer'
import FilterRowContainer from '../FilterRowContainer'
import StyledTableCell from '../StyledTableCell'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { SpinnerModal } from '../Modals/SpinnerModal';
import ConfirmDialog from '../ConfirmDialog'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },

    checkboxRoot: {
        color: "#134169!important"
    }

});

export function ListTrucks() {
    const resource = global.resources[useTheme().locale];
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [trucks, setTrucks] = useState([]);

    const [page, setPage] = React.useState(1);
    const [pageTotal, setPageTotal] = React.useState(1);
    const [size, setSize] = React.useState(10);

    const fetchTrucks = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/trucks' + `?page=${page}&size=${size}`

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        console.log('data', data)
        setTrucks(data.items)
        setPage(data.page || 1)
        setPageTotal(data.pages || 1)
        setSize(data.size)
        setLoading(false);
    }

    useEffect(() => {
        fetchTrucks();
    }, [page, size]);

    const buttonList = [
        {
            url: "list/create",
            text: `+ ${resource.addTruck}`
        }
    ]

    //delete 
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleClickDelete = (id) => {
        setDeleteDialogOpen(true)
        setDeleteId(id)
    }

    const deleteTruck = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/trucks/' + deleteId

        await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {},
            method: 'DELETE',
        });
        setDeleteDialogOpen(false)
        fetchTrucks()
    }

    return (
        <>
            <SpinnerModal open={loading} />
            <TitleContainer title={resource.truckList} buttonList={buttonList} />
            <ContentContainer>
                <ListContainer>
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>{resource.name}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trucks.map((row, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={'list/' + row.id}>{row.name}</Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Link to={'list/' + row.id}><IconButton size='small' style={{ color: '#134169' }}><ModeEditOutlineOutlinedIcon /></IconButton></Link>
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }} onClick={() => handleClickDelete(row.id)}><DeleteOutlineIcon /></IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListContainer>
                <PaginationRowContainer handleChangePage={setPage} pageTotal={pageTotal} page={page} />
            </ContentContainer>
            <ConfirmDialog content={resource.deleteTruck} open={deleteDialogOpen} handleCancel={() => setDeleteDialogOpen(false)} handleConfirm={deleteTruck} />
        </>
    )
}