import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const styles = {
    containerStyles: {
        color: '#3c5064 !important',
        marginTop: 20,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
    }
}

function ContentContainer(props) {
    const { classes, children, style } = props;
    return (
        <Box className={classes.containerStyles} style={style}>
            {children}
        </Box>
    );
}

export default withStyles(styles)(ContentContainer);