//production
global.rootUrl = "https://06c.666.mytemp.website/";
//development
//global.rootUrl = "https://localhost:44380/";
global.localeList = [
    {
        title: "English",
        value: "enUS"
    },
    //{
    //    title: "繁體中文",
    //    value: "zhHK"
    //},
]
//20 route colors
global.colorList = [
    "#46a7a2",
    "#c6d9df",
    "#e7a564",
    "#b17431",
    "#8bae7c",
    "#353a29",
    "#f1aab2",
    "#eae1a9",
    "#c25469",
    "#2d505d",

    "#49a6c6",
    "#e5ebdc",
    "#57436d",
    "#99aba0",
    "#5d5592",
    "#5ed8d3",
    "#7a8c75",
    "#b4b69c",
    "#b494c4",
    "#6b8a8b",
]
global.resources = {
    "enUS": {
        home: "Home",
        planning: "Planning",
        sessions: "Sessions",
        uploadFile: "Upload File",
        staff: "Staff",
        deliveries: "Deliveries",
        dispatch: "Dispatch",
        mapView: "Map View",
        viewOrders: "View Orders",
        viewRoutes: "View Routes",
        viewDeliveries: "View Deliveries",
        viewDeliveryDays: "View Delivery Days",
        orderManagement: "Order Management",
        reports: "Reports",
        customerManagement: "Customer Management",
        add: "Add",
        search: "Search",
        ungeocoded: "Ungeocoded",
        geocode: "Geocode",
        uploadCustomers: "Upload Customers",
        addLocation: "Add Location",
        editLocation: "Edit Location",
        name: "Name",
        locationList: "Locations",
        deleteLocation: "Delete Location",
        errorLocationName: "Error Location Name",
        basicInformation: "Basic Information",
        location: "Location",
        driver: "Driver",
        addDriver: "Add Driver",
        editDriver: "Edit Driver",
        driverList: "Drivers",
        errorDriverName: "Error Driver Name",
        deleteDriver: "Delete Driver",
        truck: "Truck",
        addTruck: "Add Truck",
        editTruck: "Edit Truck",
        truckList: "Trucks",
        errorTruckName: "Error Truck Name",
        deleteTruck: "Delete Truck",
        save: "Save",
        cancel: "Cancel",
        confirmLeaving: "Confirm Leaving?",
        confirm: "Confirm",
        permissionId: "Permission",
        superAdmin: "Super Admin",
        admin: "Admin",
        email: "Email",
        role: "Role",
        addStaff: "Add Staff",
        editStaff: "Edit Staff",
        errorStaffName: "Error Staff Name",
        loginDetails: "Login Details",
        confirmPassword: "Confirm Password",
        errorStaffPassword: "Error Staff Password",
        password: "Password",
        firstName: "First Name",
        lastName: "Last Name",
        ok: "OK",
        customerPerformance: "Customer Performance",
        driverCompliance: "Driver Compliance",
        driverPerformance: "Driver Performance",
        routeLevelReport: "Route Level Report",
        stopLevelReport: "Stop Level Report",
        generateReport: "Generate Report",
        html: "HTML",
        pdf: "PDF",
        format: "Format",
        region: "Region",
        all: "All",
        can: "CAN",
        from: "From",
        to: "To",
        sortBy: "Sort By",
        emailAddress: "Email Address",
        compressZip: "Compress (ZIP) Emailed Output",
        generate: "Generate",
        errorUploadFile: "Error Upload File",
        errorReportTime: "Date(s) selected is not valid. Please select valid dates and perform action!",
        chooseDate: "Choose a date",
        shipmentFile: "Shipment File",
        upload: "Upload",
        tenMostRecentSessions: "10 most recent plan sessions",
        showMySessions: "Show My Sessions",
        iHaveVerified: "I have verified this information to be correct",
        delete: "Delete",
        findPlanSessions: "Find plan sessions",
        go: "GO",
        userName: "Username",
        deliveryDate: "Delivery Date",
        sessionName: "Session Name",
        shipmentCount: "Shipment Count	",
        planCount: "Plan Count",
        sessionForDate: "Sessions for delivery date",
        creationDate: "Creation Date",
        shipments: "Shipments",
        routes: "Routes",
        stops: "Stops",
        distance: "Distance",
        duration: "Duration",
        status: "Status",
        date: "Date",
        filename: "Filename",
        sizebytes: "Size (bytes)",
        user: "User",
        uploadedFiles: "Uploaded files for this session",
        session: "Session",
        stopNumber: "Stop #",
        customerCustom: "Customer Custom",
        city: "City",
        arrived: "Arrived",
        serviceTime: "Service Time",
        invoiceCustom: "Invoice Custom",
        itemCustom: "Item Custom",
        productDesc: "Product Description",
        quantity: "Quantity",
        route: "Route",
        vehicle: "Vehicle",
        startTime: "Start Time",
        finishTime: "Finish Time",
        startingDepot: "Starting Depot",
        endingDepot: "Ending Depot",
        address: "Address",
        customer: "Customer",
        orderNumber: "Order #",
        timeWindow: "Time Window",
    },
    "zhHK": {

    },
}
