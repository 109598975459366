import React, { useState } from 'react';
import { Route, Redirect, withRouter, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { ListStaffs } from './components/Staffs/ListStaffs';
import { EditStaff } from './components/Staffs/EditStaff';
import { ListLocations } from './components/Locations/ListLocations';
import { EditLocation } from './components/Locations/EditLocation';
import { ListTrucks } from './components/Trucks/ListTrucks';
import { EditTruck } from './components/Trucks/EditTruck';
import { ListDrivers } from './components/Drivers/ListDrivers';
import { EditDriver } from './components/Drivers/EditDriver';
import { Reports } from './components/Reports/Reports';
import { UploadFile } from './components/Planning/UploadFile';
import { Sessions } from './components/Planning/Sessions';
import { Plans } from './components/Planning/Plans';
import { Mapping } from './components/Planning/Mapping';
import './global.js';
import './custom.css'
import { Login } from './components/Authentication/Login';
import { useEffect } from 'react';

function App({ location }) {
    const [user, setUser] = useState({
        firstName: "",
        fullName: "",
        userId: 0,
        email: "",
        role: "",
        isLoggedIn: null
    })
    const [selectedLocationId, setSelectedLocationId] = useState()
    const checkLoggedInStatus = async () => {
        var url = global.rootUrl + 'api/users/logged-user';
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        try {
            var response = await fetch(url, {
                headers: myHeaders
            });
            var data = await response.json();
            if (response.status === 200) {
                console.log("LOGGED IN with response = ", data)
                await setUser({ firstName: data.firstName, fullName: data.fullName, userId: data.userId, email: data.email, role: data.role, isLoggedIn: true });

            } else {
                console.log("NOT LOGGED IN")
                await setUser({ isLoggedIn: false })

            }
        } catch (e) {
            console.log("Caught Exception with message = ", e);
        }
    }

    useEffect(() => {
        checkLoggedInStatus()
    }, [])

    return (
        <>
            {!user.isLoggedIn ? (
                user.isLoggedIn === false ? (
                    <>
                        {location.pathname !== "/login" ? <Redirect to={{ pathname: '/login', state: { returnUrl: location.pathname } }} /> : null}
                        <Route path='/login'><Login returnUrl={location.state ? location.state.returnUrl : "/overview"} /></Route>
                    </>) : null
            ) : (
                <Layout user={user} selectedLocationId={selectedLocationId} handleLocationChange={setSelectedLocationId}>
                    <Switch>
                        <Route path='/home' ><Home isLoggedIn={user.isLoggedIn} role={user.role} /></Route>

                        {/*Staff START*/}
                        <Route exact path='/staff/list'><ListStaffs /></Route>
                        <Route path='/staff/list/:id'><EditStaff user={user} /></Route>
                        {/*Staff END*/}

                        {/*Location START*/}
                        <Route exact path='/location/list'><ListLocations /></Route>
                        <Route path='/location/list/:id'><EditLocation /></Route>
                        {/*Location END*/}

                        {/*Driver START*/}
                        <Route exact path='/driver/list'><ListDrivers /></Route>
                        <Route path='/driver/list/:id'><EditDriver /></Route>
                        {/*Driver END*/}

                        {/*Truck START*/}
                        <Route exact path='/truck/list'><ListTrucks /></Route>
                        <Route path='/truck/list/:id'><EditTruck /></Route>
                        {/*Truck END*/}

                        {/*Report START*/}
                        <Route path='/report/:type'><Reports /></Route>
                        {/*Report END*/}

                        {/*Planning START*/}
                        <Route path='/planning/sessions'><Sessions selectedLocationId={selectedLocationId} /></Route>
                        <Route path='/planning/session/:id/plans'><Plans /></Route>
                        <Route path='/planning/session/:id/mapping'><Mapping selectedLocationId={selectedLocationId} /></Route>
                        <Route path='/planning/uploadfile'><UploadFile selectedLocationId={selectedLocationId} /></Route>
                        {/*Planning END*/}


                        <Route path='*' ><Home isLoggedIn={user.isLoggedIn} /></Route>
                    </ Switch>
                </Layout>
            )}
        </>
    );

}
export default withRouter(App);