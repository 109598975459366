import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import { Typography } from '@material-ui/core';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
        background: '#3c5064'
    },
});

const CustomTooltips = ({ tootipsText }) => (
    <NoMaxWidthTooltip title={<Typography>{tootipsText}</Typography>} arrow>
        <HelpIcon style={{ marginLeft: 5, fontSize: 19, paddingBottom: 2 }} />
    </NoMaxWidthTooltip>
)

export default CustomTooltips;