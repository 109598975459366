import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Box, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const styles = {
    containerStyles: {
        padding: 10,
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: "#3c5064",
        display: 'flex',
        justifyContent: "space-between"
    }
}

function TitleContainer(props) {
    const { classes, title, buttonList = [], isReportPage = false, timePeriod, handleTimeClick, pickerOpen } = props;
    return (
        <Box className={classes.containerStyles}>
            <Typography variant="h5" color="inherit">{title}</Typography >
            <Box style={{ display: 'flex', paddingLeft: 15, paddingRight: 15, paddingTop: 8, paddingBottom: 8 }}>
                {buttonList.map((btn, i) => {
                    return <Link key={i} to={btn.url} style={{ color: 'white', fontSize: 16, paddingLeft: 15, paddingRight: 15 }}>{btn.text}</Link>
                })}
                {
                    isReportPage &&
                    <Button
                        style={{ color: "white", outline: "none", padding: 0, lineHeight: '22.88px' }}
                        id="basic-button"
                        aria-haspopup="true"
                        onClick={handleTimeClick}
                        endIcon={pickerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        {timePeriod}
                    </Button>
                }
            </Box>
        </ Box >
    );
}

export default withStyles(styles)(TitleContainer);