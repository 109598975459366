import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

const StyledTableCell = withStyles({
    root: {
        color: "#3c5064",
        borderBottomColor: "rgba(84, 84, 88, 0.65)",
        paddingLeft: 0,
        paddingRight: 0
    }
})(TableCell);

export default StyledTableCell;