import { IconButton, TableContainer, Table, TableHead, TableBody, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TitleContainer from '../TitleContainer'
import PaginationRowContainer from '../PaginationRowContainer'
import ListContainer from '../ListContainer'
import ContentContainer from '../ContentContainer'
import FilterRowContainer from '../FilterRowContainer'
import StyledTableCell from '../StyledTableCell'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { SpinnerModal } from '../Modals/SpinnerModal';
import ConfirmDialog from '../ConfirmDialog'

const useStyles = makeStyles({

    table: {
        backgroundColor: "white",
        borderRadius: 6,
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 25
    },

    checkboxRoot: {
        color: "white!important"
    }

});

export function ListStaffs() {
    const resource = global.resources[useTheme().locale];
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [staffs, setStaffs] = useState([]);
    const [filters, setFilters] = React.useState([
        {
            id: "permissionId",
            currentValue: ""
        }
    ]);

    const filterList = [
        {
            id: "permissionId",
            title: resource.permissionId,
            options: [
                {
                    id: 1,//enum Permission.SuperAdmin
                    name: resource.superAdmin
                },
                {
                    id: 2,//enum Permission.Admin
                    name: resource.admin
                },
                {
                    id: 3,//enum Permission.Staff
                    name: resource.staff
                },
            ],
        }
    ]
    const handleFiltersChange = (index, value) => {
        setFilters(prev => {
            prev[index].currentValue = value
            return [...prev]
        })
    }

    const [page, setPage] = React.useState(1);
    const [pageTotal, setPageTotal] = React.useState(1);
    const [size, setSize] = React.useState(10);

    const fetchStaffs = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/users' + `?page=${page}&size=${size}&query=${searchText}`
        filters.forEach(f => {
            if (!!f.currentValue) {
                url += `&${f.id}=${f.currentValue}`
            }
        })
        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });

        var data = await response.json();
        console.log('data', data)
        setStaffs(data.items)
        setPage(data.page || 1)
        setPageTotal(data.pages || 1)
        setSize(data.size)
        setLoading(false);
    }

    useEffect(() => {
        fetchStaffs();
    }, [searchText, filters, page, size]);

    const onSearchTextChange = (event) => {
        setSearchText(event.target.value);
    }

    const buttonList = [
        {
            url: "list/create",
            text: `+ ${resource.addStaff}`
        }
    ]

    //delete 
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    const handleClickDelete = (id) => {
        setDeleteDialogOpen(true)
        setDeleteId(id)
    }

    const deleteStaff = async () => {
        //const token = await authService.getAccessToken(); 
        var url = global.rootUrl + 'api/users/' + deleteId

        await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {},
            method: 'DELETE',
        });
        setDeleteDialogOpen(false)
        fetchStaffs()
    }

    return (
        <>
            <SpinnerModal open={loading} />
            <TitleContainer title={resource.staffList} buttonList={buttonList} />
            <ContentContainer>
                <FilterRowContainer onChange={onSearchTextChange} value={searchText} filters={filters} filterList={filterList} handleFiltersChange={handleFiltersChange} />
                <ListContainer>
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>{resource.staff}</StyledTableCell>
                                    <StyledTableCell>{resource.email}</StyledTableCell>
                                    <StyledTableCell>{resource.role}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {staffs.map((row, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell component="th" scope="row">
                                            <Link to={'list/' + row.id}>{row.firstName} {row.lastName}</Link>
                                        </StyledTableCell>
                                        <StyledTableCell>{row.email}</StyledTableCell>
                                        <StyledTableCell>{row.displayPermission}</StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Link to={'list/' + row.id}><IconButton size='small' style={{ color: '#134169' }}><ModeEditOutlineOutlinedIcon /></IconButton></Link>
                                            <IconButton size='small' style={{ color: '#134169', marginLeft: 10 }} onClick={() => handleClickDelete(row.id)}><DeleteOutlineIcon /></IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ListContainer>
                <PaginationRowContainer handleChangePage={setPage} pageTotal={pageTotal} page={page} />
            </ContentContainer>
            <ConfirmDialog content={resource.deleteStaff} open={deleteDialogOpen} handleCancel={() => setDeleteDialogOpen(false)} handleConfirm={deleteStaff} />
        </>
    )
}