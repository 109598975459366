import { Typography, Grid, Button } from '@material-ui/core';
import React, { useState, } from 'react';
import TitleContainer from '../TitleContainer'
import ContentContainer from '../ContentContainer'
import { useTheme } from '@mui/material/styles';
import CustomTextField from '../TextFields/CustomTextField'
import ValidationDialog from '../ValidationDialog'
import { SpinnerModal } from '../Modals/SpinnerModal';
import { useHistory } from "react-router-dom";

export function UploadFile({ selectedLocationId }) {
    const resource = global.resources[useTheme().locale];
    const history = useHistory();
    const [date, setDate] = useState("")
    const [file, setFile] = useState()
    const [filename, setFilename] = useState("");
    const [loading, setLoading] = useState(false);
    //validation
    const [validationMsg, setValidationMsg] = useState("");
    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const validateForm = () => {
        if (!date || !file) {
            setValidationMsg(resource.errorUploadFile)
            setValidationDialogOpen(true)
            return
        }
        return submit()
    }

    const submit = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/planning/uploadfile';
        const formData = new FormData();
        formData.append('locationId', selectedLocationId);
        formData.append('date', date);
        formData.append('file', file);
        formData.append('fileName', file.name);
        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            //headers: { "Content-Type": "multipart/form-data" },
            method: "POST",
            body: formData
        });
        if (response.ok) {
            var data = await response.json();
            console.log("upload RESPONSE's data only = ", data);
            history.push("/planning/sessions")
        }
        setLoading(false);
    }
    const handleFileUpload = (e) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const { name } = file;
        setFile(file)
        setFilename(name);
    }
    return (
        <>
            <SpinnerModal open={loading} />
            <ValidationDialog content={validationMsg} open={validationDialogOpen} setOpen={setValidationDialogOpen} />
            <TitleContainer title={resource.uploadFile} />
            <ContentContainer style={{ width: '50%' }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.chooseDate}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextField type="date" value={date} onChange={(event) => setDate(event.target.value)} />
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{resource.shipmentFile}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Button
                            component="label"
                            variant="contained"
                        >
                            Choose File
                            <input
                                type="file"
                                hidden
                                accept=".txt"
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1">{filename}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button style={{ padding: 11, color: "#007dc0", fontSize: 17 }} onClick={validateForm}>{resource.upload}</Button>
                    </Grid>
                </Grid>
            </ContentContainer>
        </>
    )
}