import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const styles = {
    containerStyles: {
        marginLeft: 20,
        marginRight: 20,
        marginTop: 20
    },
}

function ListContainer(props) {
    const { classes, children } = props;
    return (
        <Box className={classes.containerStyles}>
            {children}
        </Box>
    );
}

export default withStyles(styles)(ListContainer);