import { Typography, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CustomTextField from '../TextFields/CustomTextField'
import { useParams } from 'react-router';
import { SpinnerModal } from '../Modals/SpinnerModal';
import TitleContainer from '../TitleContainer'
import { useTheme } from '@mui/material/styles';
import ContentContainer from '../ContentContainer'
import TabsViewContainer from '../TabsViewContainer'
import TabContentCard from '../TabContentCard'
import { useHistory } from "react-router-dom";
import ValidationDialog from '../ValidationDialog'

//tab
const BasicInformationTabContent = ({
    name,
    setName,
}) => {
    const resource = global.resources[useTheme().locale];
    console.log('name', name)
    return (
        <>
            <TabContentCard title={`${name}`}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="body1">{resource.name}</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <CustomTextField value={name} onChange={(event) => setName(event.target.value)} />
                    </Grid>
                </Grid>
            </TabContentCard>
        </>
    )
}
export const EditDriver = () => {
    const resource = global.resources[useTheme().locale];
    const history = useHistory();
    let { id } = useParams();
    const [loading, setLoading] = useState(true);
    //basic info tab
    const [name, setName] = useState("");

    const fetchDriver = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/drivers/' + id;

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: {}
        });
        if (response.ok) {
            var data = await response.json();
            console.log('data', data)
            //basic info tab
            setName(data.name);
        }
        setLoading(false);
    }

    const submitDriver = async () => {
        setLoading(true);
        var url = global.rootUrl + 'api/drivers';
        var body = {
            id: (id !== "create" ? id : 0),
            //basic info tab
            name,
        };

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(body)
        });
        if (response.ok) {
            var data = await response.json();
            console.log("sumbit driver RESPONSE's data only = ", data);
        }
        setLoading(false);
        history.push('/driver/list')
    }

    const back = () => {
        history.push('/driver/list')
    }

    useEffect(() => {
        if (id !== "create")
            fetchDriver();
        else
            setLoading(false);
    }, [id])

    //validation
    const [validationMsg, setValidationMsg] = useState("");
    const [validationDialogOpen, setValidationDialogOpen] = useState(false);

    const validateForm = () => {
        if (!name) {
            setValidationMsg(resource.errorDriverName)
            setValidationDialogOpen(true)
            return
        }

        return submitDriver()
    }

    //tabs
    const basicTab = {
        label: resource.basicInformation,
        content: <BasicInformationTabContent
            name={name}
            setName={setName}
        />
    }
    const [tabList, setTabList] = useState([basicTab]);
    useEffect(() => {
        const res = [basicTab]
        setTabList([...res])
    }, [
        resource,
        name,
    ])

    return (
        <>
            <SpinnerModal open={loading} />
            <ValidationDialog content={validationMsg} open={validationDialogOpen} setOpen={setValidationDialogOpen} />
            <TitleContainer title={id === "create" ? resource.addDriver : resource.editDriver} />
            <ContentContainer>
                <TabsViewContainer tabList={tabList} handleClickCancel={back} handleClickSave={validateForm} />
            </ContentContainer>
        </>
    )
}