import React, { useState } from 'react';
import CustomTextField from '../TextFields/CustomTextField'
import { Box, Button, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

export function Login({ returnUrl }) {
    let location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const login = async () => {
        var authModel = { email: email, password: password, isPersistant: true };
        var url = global.rootUrl + 'api/auth/authenticate';
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        console.log("LOGIN ==> autheModel = ", authModel);

        var response = await fetch(url, {
            //TODO: include token usage if necessary
            //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            headers: myHeaders,
            method: 'POST',
            body: JSON.stringify(authModel)
        });
        var data = await response.json()
        console.log("LOGIN ==> response = ", data);
        window.location = returnUrl || "/overview";
    }

    //const logout = async () => {
    //    var url = global.rootUrl + 'api/auth/unauthenticate';
    //    var myHeaders = new Headers();

    //    var response = await fetch(url, {
    //        //TODO: include token usage if necessary
    //        //headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //        headers: myHeaders,
    //    });
    //    console.log("LOGOUT ==> response = ", response);
    //}

    return (
        <Box style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            width: 535,
            height: 600,
            margin: "auto",
            backgroundColor: "#f5f8fa",
            padding: 20,
            marginTop: 100
        }}>
            <Typography variant="h5" className="text-main">Sign in to Route Planner On-demand</Typography>
            <CustomTextField placeholder="Email" value={email || ""} onChange={(event) => { event.persist(); setEmail(event.target.value) }} />
            <CustomTextField type="password" placeholder="Password" value={password || ""} onChange={(event) => { event.persist(); setPassword(event.target.value) }} />
            <Button style={{ borderRadius: 0, padding: 11, color: "#007dc0", fontSize: 17 }} onClick={login}> LOGIN </Button>
        </Box>
        
    );
}
